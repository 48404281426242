.orders-vector{
    width:300px;

    @media (min-width: 992px) {
        width: 500px;
    }

}

.filter-head, 
.status {
    width: 100%;
    margin: auto;
}

.date-input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: .5rem;
    background: transparent;
}

.main-content {
    @media (min-width: 992px) {
        padding-left: 2rem;
    }
}