.wallet-vector{

    width:300px;

    @media (min-width: 992px) { 
        width: 400px;
    }

}

