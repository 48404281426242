.nearby-partner-bill-upload-vector{
    width:300px;

    @media (min-width: 992px) {
        width:500px;
    }

}

.box-partner-detail-logo{

    height: 200px !important;

    @media (min-width: 992px) {
        height:450px !important;
    }

}

.sort {
    width: 75%;
    display: inline-block;
}

.filter-icon {
    height: .9em;
}

.category-title {
    h4 {
        font-size: 1.2rem;

        @media (min-width:992px) {
            font-size: 2rem;
        }
    }
}

.meter-handle {
    transform: translate(0px, -8px)!important;
}