.referral-vector{

    height:250px;

    @media (min-width: 992px) { 

        height:300px;

    }

}

.referral-block {
    border-radius: 1rem;
    width: 90%;
    box-sizing: border-box;
    padding: 5rem 3rem;
    margin-bottom: 3rem;
}

.referral-image {
    display: block;
    width: 90%;
    margin:auto;
    margin-top: 3rem;
}