.slick-prev:before, .slick-next:before {
    color:#0078D7;
    line-height: 41px;
    margin-left: 4px;
}

.slick-slide img{
    display: inline;
}

.slick-disabled{
    display: none !important;
}

.slick-track{
    margin:0;
}

.slick-list{
    margin:0 -15px;
}

// Banner Carousel

.carousel-control-prev, .carousel-control-next{
    z-index: 0;
}

.partner-carousel-item{

    width:100%;
    height:200px;
    background-size:cover;
    border-radius: 8px;

    @media (min-width: 992px) { 
        height:450px;
    }
}