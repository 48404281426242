.box-voucher-detail-logo{

    height: 345px !important;

    @media (min-width: 992px) {
        height:252px !important;
    }

}

.voucher-detail-logo-wrapper{
    width:100%;
    height:80px;
    background-color: #fff;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: 80% auto;
    background-position: center center;

    @media (min-width: 992px) {
        height:160px;
    }

}

.btn-primary.disabled, .btn-primary:disabled, .btn-primary {
    background-color: rgb(32, 59, 207);
    border-color: rgb(32, 59, 207);
}

.tab-pane ol {
    padding-left: 1rem;
}

.voucher-deliveryType {
    font-size: 0.8rem;
    @media (min-width: 992px) {
        font-size: 1rem;
    }

}

.voucher-title{
    font-size: 25px;
    @media (min-width: 992px) {
        font-size: 30px;
    }
}