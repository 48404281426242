
.navbar{

    background: #fff;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.25);
    padding: 5px 0;
    z-index:1;

    @media (min-width: 992px) {
        padding: 15px 0;
    }

    .navbar-nav {

        .nav-item {

            position: relative;
            cursor: pointer;

            img {
                height: 1rem;
                width: auto;

                @media (min-width: 992px) { 
                    height: 1.5rem;
                }
            }

            .nav-link {
                color:#222A73;
                padding:0 5px;

                img {
                    height: 1rem;
                    width: auto;
                }

                &.is-active{
                    color: #FFA300;
                }

                @media (min-width: 992px) { 
                    padding: 0 1.5rem;

                    img {
                        height: 1.5rem;
                    }
                }

                .wrp-icon {
                    height: .95rem;
            
                    @media (min-width: 992px) { 
                        height: 1.5rem;
                    }
                }    

            }

            .nav-text{
                color:#222A73;
                padding: 0;
                display: block;
            }

            .dropdown-menu{
                position: absolute;

                .dropdown-item {
                    display: flex;
                    align-items: center;    
                    img {
                        margin-right: .5rem;
                    }
                }
            }

        }

    }
}

.mobile-icons {
    margin-right: 1rem !important;
    @media (min-width: 992px) {
        margin-right: .3rem !important;
        margin-bottom:0.1rem;
    }
}

.dropdown-icons {
    img {
        height: .8rem!important;
    }
}
