.card--shopping-site-logo {
    border: none;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, .08);
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: 100% auto;

    .card-body {

        padding: 0;

        .logo {

            border-radius: 8px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.79) 66.41%, #FFFFFF 100%);

            img {
                height: 30px;
                padding: 80px 0 10px 0;
                box-sizing: content-box;
                margin: 0 auto;
                display: block;

                @media (min-width: 992px) {
                    height: 50px;
                    padding: 180px 0 10px 0;
                }

            }

        }

    }
}


.box-shopping-site-detail-logo {

    height: 345px !important;

    @media (min-width: 992px) {
        height: 450px !important;
    }

}

.shopping-sites-bill-upload-vector {
    width: 300px;

    @media (min-width: 992px) {
        width: 500px;
    }

}

.card--shopping-site-detail-logo {
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: none;
    min-height: 345px;
}

.shopping-sites-vecotr {
    width: 300px;

    @media (min-width: 992px) {
        width: 500px;
    }
}

.filter-categories {
    padding-left: 0;
    margin-top: -1rem;
}

.banner-web,
.banner-mob {
    width: 100vw;
}

.banner-mob {
    display: block;

    @media (min-width: 990px) {
        display: none;
    }
}

.banner-web {
    display: none;

    @media (min-width: 990px) {
        display: block;
    }
}

.bread-crumbs {
    position: absolute;
    color: white;
    top: 1rem;
    left: 1rem;
    z-index:2;
}

.logo-container {
    width: 150px;
    position: absolute;
    z-index: 2;
    bottom: -3rem;
    left: 1rem;

    @media (min-width: 990px) {
        width: 200px;
        bottom: -7rem;
    }

    .card {
        border-radius: 8px;
        box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
        border: none;
    }

    .card-body {
        padding: 0;
    }
}

.affliate-logo-img {
    width: 80%;
    margin: auto;
    display: block;
    padding: 3rem 0;

    @media(min-width: 990px) {
        padding: 4rem 0;
    }
}

.affiliate-banner {

    height: 25vh;
    position: relative;

    @media (min-width: 990px) {
        height: 30vh; 
        background-color: #fff;
    }

    &::after {
        background: linear-gradient(to top right, #ed709d, #ec8a6c);
        left:0;
        right:0;
        content: " ";
        display: block;
        z-index:1;
        top:0;
        bottom:0;
        mix-blend-mode: multiply;
        position: absolute;
    }
}