.container--refferal{

    background-color: #131848;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 150px auto;
    padding:80px 15px;


    @media (min-width: 992px) { 

        background-size: 700px auto;
        background-position: 98% 0%;
        padding: 9rem 1rem; 
        margin-right: 2rem;
    }

    .band-copy {
        margin-top: -2rem;

        @media (min-width: 992px) { 
            margin-top: 0rem;
        }
    }

    .row--home-section-title{
        
        margin-bottom:0px;
        
        h2{
         
            font-size: 1rem;

            @media (min-width: 992px) { 

                font-size: 2rem;
        
            }

        }

    }


}

.about-bg {
    background-size: cover!important;
    position: relative;
    .about-overlay {
        width: 100%;
        height: 100%;
        background: linear-gradient( to right, #131848, #000);
        position: absolute;
        top: 0;
        left: 0;
        mix-blend-mode: darken;
        opacity: .6;
    }
}

.container-fluid--home-section{

    padding:15px 15px;

    @media (min-width: 992px) { 

        padding:50px 15px;

    }

    .row--home-section-title{
        
        margin-bottom:0px;
        
        h2{
         
            font-size: 1rem;

            @media (min-width: 992px) { 

                font-size: 2rem;
        
            }

        }

        .more{

            font-size: 1rem;

            @media (min-width: 992px) { 

                font-size: 1.2rem;
        
            } 

        }

    }

}



.bill-pay-icon-container{

    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;

    .bill-pay-service-icon{

        flex:1;
        flex-basis: 33.33%;
        margin: 30px 0;

        @media (min-width: 992px) { 

            flex-basis: auto;
    
        }

    }

}

.card--all-brands{

    border-radius:8px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
    border: none ;

    .card-body{

        padding:0;

        .all-brand-image-wrapper{

            width:100%;
            height:80px;
            background-size: 80% auto;
            background-position: center center;
            background-repeat: no-repeat;

            @media (min-width: 992px) {
                background-size: auto 50%;
                height:160px;
            }

        }
    }

}

.slider-shimmer{
    width:100%;
    height:176px;

    @media (min-width: 992px) {
        height:300px;
    }
}

.shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 500px; 
    display: inline-block;
    position: relative; 
    
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;

    &.box{
        height:100px;
        width:100%;
        border-radius: 8px;

        @media (min-width: 992px) {
            height:200px;
        }

    }

    &.heading{

        height:20px;
        width:150px;
        border-radius: 4px;

        @media (min-width: 992px) {
            width:300px;
        }
    }

}

@-webkit-keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
  }

  .band-copy {
    .home-btn {
        font-size: .8rem;
        width: 50%;
        letter-spacing: .1rem;
      }
  }

  .home-btn {
    padding: .8rem 0rem;
    font-size: .8rem;
    letter-spacing: .1rem;
    width: 100%;
    text-transform: uppercase;
  }
