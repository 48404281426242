
.bill-pay-page{
    width: 100%;
    min-height: 300px;
    display: block;
    position: relative;
}

 .container-fluid--bill-pay-icon{
     height:80px;

     @media (min-width: 992px) { 

        height:200px
    
    }

 }

.card--bill-pay-form{
    border: none;
    background-color: #FFFFFF;
    border-radius:10px;
    margin-top: -30px;
    margin-bottom: 20px;

   
}

.bill-pay-page-icon-container{

    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;

    .bill-pay-service-icon{

        flex:1;
        flex-basis: 33.33%;
        margin-bottom:50px;
    }

}

.bill-pay-service-icon-img{

    height:30px;

    @media (min-width: 992px) {
        height: 60px;
    }

}

.bill-pay-service-icon-label{
    font-size:12px;

    @media (min-width: 992px) {
        font-size:14px;
    }
}

.nav--plans{

    .nav-tabs{
        overflow-x: scroll;
        overflow-y: hidden;

        @media (min-width: 992px) { 

            overflow-x: hidden;
        
        }

    }

}