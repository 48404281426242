.container-fluid--footer{

    background-color: #131848;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
    color:#fff;
    padding: 80px 15px;

}

.container-fluid--sub-footer{

    background-color: #141414;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
    color:#fff;

}