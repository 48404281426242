.card--carousel-item {

    border-radius: 8px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
    border: none;

    .card-body {
        padding: 0;
    }

}

.card--carousel-item--shopping-sites {

    .card-body {

        min-height: 100px;

        @media (min-width: 992px) {

            min-height: 320px;

        }

        .logo {

            position: absolute;
            bottom: 0;
            width: 100%;
            padding-bottom: 5px;
            padding-top: 60px;

            @media (min-width: 992px) {
                padding-bottom: 25px;
            }

            img {
                height: 25px;
                margin: 0 auto;
                display: block;

                @media (min-width: 992px) {
                    height: 50px;
                }

            }
        }
    }

}

.card--carousel-item--nearby-partner {

    border-radius: 8px;

    .card-body {

        img {
            width: 100%;
        }

        .category-label {
            position: absolute;
            bottom: 0;
            text-align: center;
            width: 100%;
            line-height: 12px;
            padding-bottom: 5px;

            span {

                color: #fff;
                font-size: 12px;

                @media (min-width: 992px) {
                    font-size: 20px;
                    line-height: 80px;
                }

            }
        }
    }

}

.card--carousel-item--vouchers {

    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);

    .card-body {

        padding: 20px 10px;

        @media (min-width: 992px) {
            padding: 30px;
        }

        img {
            height: 25px;
            margin: 0 auto;
            display: block;

            @media (min-width: 992px) {
                height: 50px;
            }

        }

        .delivery-type-label {
            position: absolute;
            top: 16px;
            // right:20px;
            font-weight: 600;
            font-size: 13px;
            display: none;

            @media (min-width: 992px) {
                display: inline-block;
            }

        }

        .delivery-detail {
            font-size: .8rem;
            text-align: center;
            margin-top: .5rem;

            img {
                height: 1rem;
                display: inline-block;
                margin-right: .3rem;
            }
        }

    }

    .card-footer {
        background-color: #eff6fe;
        border: none;
        display: none;

        @media (min-width: 992px) {
            display: block;
        }

        .star {
            height: .8rem;
            margin-right: 3px;
        }

        @media (min-width: 992px) {
            .star {
                height: .8rem;
            }
        }

        .brand-name-label {
            font-size: 18px;
            font-weight: 600;
            color: #333333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}



.card--carousel-item--billpay {

    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);

    .card-body {

        padding: 20px 5px;

        @media (min-width: 992px) {
            padding: 30px;
        }

        img {
            height: 40px;
            margin: 0 auto;
            display: block;

            @media (min-width: 992px) {
                height: 50px;
            }

        }

        p{
            font-size:10px;

            @media (min-width: 992px) {
                font-size:16px;
            }

        }
    }

}


.voucher-delivery-detail {
    color: #666;
    font-size: 12px;
    font-weight: 500;

    img {
        height: .8rem;
        margin-right: .5rem;
    }

    @media (min-width: 992px) {
        font-size: 16px;

        img {
            height: 1.2rem;
        }
    }


}

.card--carousel-item--offers {

    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    .card-body {
        border-radius: 8px 8px 0 0;
    }

    .card-footer {
        background: #fff;
        border: none;
        min-height: 125px;
    }

}

.card--partner {

    border: none;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    .card-body {
        border-radius: 8px 8px 0 0;
        padding: 0;
    }

    .card-footer {
        background: #fff;
        border: none;

        .star {
            height: 13px;
            margin-right: 5px;
        }
    }

}

.star {
    height: .75rem;
    margin-right: 3px;
}

@media (min-width: 992px) {
    .address-wrapper {
        margin-left: 3rem;
    }

    .star {
        height: 1.5rem;
    }
}