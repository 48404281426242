.coupon-wrapper{
    display:flex;
    align-items:center;
    justify-content:center;

    div{
        position:relative;
        width:300px;
        height:250px;

        @media (min-width: 992px) {

            width:400px;
            height:300px;

        }

        img{
            width:300px;
            position:absolute;
            top:0;
            left:0;

            @media (min-width: 992px) {
                width:400px;
            }

        }

        h3{
            color:#FFCC00;
            font-size:100px;
            font-weight:600;
            transform: rotate(-18deg);
            position:absolute;
            top:40px;
            left: 120px;

            @media (min-width: 992px) {
                font-size:120px;
                top:60px;
                left: 160px;
            }

        }

    }

}