.rewards-card {
    width: 300px;
    height: 300px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
    border: none;
    margin: 2rem auto;
    overflow: hidden;

    .reward-icon {
        width: 100%;
        position: absolute;
        top: 0%;
        left: 0%;
        // transform: translate3d(-50%, -50%, 0);

        img {
            width: 100%;
            margin: auto;
        }

        h4 {
            transform: translate3d(-50%, calc(-50% + 6rem), 0);
        }
    }
    .reveal-icon {
        width: 90%;
        margin: .5rem auto;

        img {
            width: 80%;
            display: block;
            margin: auto;
        }
    }
}

.card-red {
    background-color: #ea5859;
} 
.card-yellow {
    background-color: #ead22e; 
}
.card-orange {
    background-color: #f3a533; 
}
.card-green {
    background-color: #89c52a; 
}
.card-blue {
    background-color: #3c7eda; 
}
.card-brown {
    background-color: #d95d2e; 
}

.offer-card {
    width: 300px;
    height: 350px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
    border: none;
    flex-direction: column;
    padding: 1rem;
    margin: 1rem auto;

    .rewards-card {
        width: 50%;
        height: 40%;
        margin: auto;
    }
}

.offer-detail {
    flex-direction: column-reverse;
    @media (min-width: 990px) {
        flex-direction: row;
    }

    h5 {
        font-weight: bold;
    }

    .offer-card {
        height: 500px;

        .rewards-card {
            width: 60%;
            height: 50%;
        }
    }   
    
}

.modal-body {
    .rewards-card {
        box-shadow: none;
        .reward-icon {
            top: 50%;
            transform: translateY(-50%);
            h4 {
                transform: none;
                font-size: 2rem;
            }
            p {
                font-size: 1.5rem;
            }
            small {
                font-size: 1.2rem;
            }
        }
    }
}

.modal-body {
    div {
        background: white;
    }
}

#wheel {
    background: transparent;
    width: 100vw;

    @media (min-width: 990px) {
        width: auto;
        margin-left: 8rem;
    }

    #canvas {
        width: 150%;

        @media (min-width: 800px) {
            width: auto;
            height: 600px;
        }

    }
}

// .secret {
//     .reward-icon {
//         color: #fff;
//         p, small {
//             color: #fff!important;
//         }
//     }
// }

.modal-backdrop.show {
    opacity: 0.8;
}
.rewards {
    .modal-content {
        background-color: transparent;
        border: none;
        .close {
            color: #fff;
            text-shadow: none;
            font-size: 2rem;
        }
    }
}
